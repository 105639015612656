import React from "react";

const Logo = () => {
  return (
    <div className="logo">
      <img
        src="/assets/logo_iac.webp"
        alt="logo_iac"
        className="h-[55px] w-[55px] md:h-[75px] md:w-[75px]"
      />
    </div>
  );
};

export default Logo;
