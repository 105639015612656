import Header from "../../components/Header";
import Activities from "../../components/Activities";
import Mission from "../../components/Mission";
import ContactUs from "../../components/ContactUs";
import Team from "../../components/Team";
import ContactForm from "../../components/ContactForm";

const HomePage = () => {
  return (
    <div className="">
      <Header />
      <Activities />
      <Mission />
      <ContactUs />
      <Team />
      <ContactForm />
    </div>
  );
};

export default HomePage;
