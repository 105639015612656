export const activities = [
  {
    img: "./images/mother-earth-day.png",
    name: "Etude d'impact & environnemental",
    description:
      " La mise en place de projets structurants doit tenir compte des caractéristiques de l’environnement humain et surtout physique dans lequel, ce projet se réalise",
    link: "services/etude-impact-envrionnematal",
  },
  {
    img: "./assets/gens.webp",
    name: "Etude sociale & démographique",
    description:
      "Les études sociales et démographiques visent à comprendre et à analyser divers aspects de la société humaine, notamment sa structure, sa composition et sa dynamique",
    link: "services/etude-sociale-et-demographique",
  },
  {
    img: "./assets/ville.png",
    name: "Etude urbaine",
    description:
      "De la collecte des données à la réalisation du diagnostic urbain en passant par l'état des lieux d'une localité, IAC dispose d'une expertise avérée. Sa méthode de travail rigoureuse basée sur l'utilisation d'outils innovants rassure tout partenaire soucieux d'acquérir des données de qualité",
    link: "services/etude-urbaine",
  },
];

export const teamMembers = [
  {
    photo: "expert-yao-Kouadio-Jean-150x150.jpg",
    name: "Yao Jean KOUADIO",
    title: "Directeur de la recherche et de la planification",
    job: "Sociologue / Démographe",
    description:
      "Diplômé des écoles internationales, cet Expert est outillé pour répondre aux questions d'ordre social notamment la dynamique familiale en Afrique Subsaharienne. Outre cela, sa formation de base, Démographe justifie amplement son intervention dans plusieurs ONG et institutions internationales. Son professionnalisme ne soufre d'aucune contestation. Ainsi, l'Expert Kouadio peut réaliser avec brio n'importe quel projet.",
  },
  {
    photo: "expert-michelle-Becanti-600x600.jpg",
    name: "Bécanti Michèle BOHOUSSOU",
    title: "Directrice administrative et financière ",
    job: "Experte en études urbaines / Sociologue",
    description:
      "La gestion de l'espace urbain est une problématique qui tutoie n'importe quel pays dans le monde en général et en particulier ceux de l'Afrique. L'Experte, de formation de base en sociologie est douée pour répondre aux questions liées à la ville, à l'urbanité, au logement, à l'habitat social... Ses performances en ce qui concerne les études qualitatives et quantitatives plus spécifiquement les études de marché sont à souligner.",
  },
  {
    photo: "Expert-gaoussou-sylla-150x150.jpg",
    name: "Gaoussou SYLLA",
    title: "Directeur des relations extérieures et des partenariats",
    job: "Géographe / Environnementaliste",
    description:
      "Pour répondre aux questions environnementales, avec cet Expert vous ne pouvez être mieux accompagnés ! Equité environnementale, modélisation des données quantitatives, nuisances environnementales (pollution de l’air et bruit) sont entre autres les problématiques qui passionnent le Docteur Sylla (PhD). Sa bonne aptitude à combiner les outils de la géomatique à ses études fait de lui un expert d'exception.",
  },
  {
    photo: "jeph-300x300.jpeg",
    name: "N'dri Jephté KOFFI",
    title: "Directeur technique et de l’innovation ",
    job: "Géomaticien (Télédétection & SIG)",
    description:
      "Avoir des données est une chose, savoir quoi en faire en est une autre. De l'acquisition à l'interprétation des données géographiques en passant par leur traitement, cet expert propose des outils d'aide à la décision aux entreprises à l'aide des SIG . N'est ce pas un soulagement pour les entreprises friandes de solutions efficientes ( innovantes et moins onéreuses) !",
  },
];

export const phone_number = "+225 07 49 30 59 43";
export const email = "contacts@improveafrica-consulting.com";
export const address = "27 BP 627 Abidjan 27";
export const linkedinLink = "https://www.linkedin.com/in/yaojeankouadio";
export const ytLink = "";
export const fbLink = "";
export const xLink = "";
